// TODO: Remove jQuery dependency
(function ($) {

    /**
     * Smooth scroll to DOM element with target ID
     * @param target event object
     * @param callback optional callback function
     */
    var scrollToAnchor = function (target, callback) {
        var $target = $(target),
            targetOffsetTop = (typeof $target == 'undefined' ? 0 : $target.offset().top),
            navbarHeight = 56,
            scrollOffset = 200,
            scrollSpeed = 500;

        var scroll_top = $('body').scrollTop() || $('html').scrollTop();
        var distance = targetOffsetTop - navbarHeight;
        var pos_to_scroll = scroll_top;

        if (distance < scroll_top) {
            if ((scroll_top - distance) > scrollOffset)
                pos_to_scroll = targetOffsetTop + scrollOffset - navbarHeight; // top
        } else {
            if ((distance - scroll_top) > scrollOffset)
                pos_to_scroll = targetOffsetTop - scrollOffset - navbarHeight;
        }

        $('html, body').stop().scrollTop(pos_to_scroll).animate({
            'scrollTop': targetOffsetTop - navbarHeight
        }, scrollSpeed, 'swing', function () {

            if ($target.length) window.location.hash = target;

            if (typeof callback === "function") {
                callback();
            }
        });
    };
    
    // Bind all anchor links with scrollToAnchor() function
    $('a[href].animate-scroll').on('click', function (e) {
        var target = this.hash;
        var $target = $(target);
        if ($target.length > 0) {
            e.preventDefault();
            scrollToAnchor(target);
        }
    });

    // Show summary navbar when scrolled
    $(window).on( "scroll", function() {
        if(window.pageYOffset > 50) {
            $('#navbar-summary .navbar').removeClass('navbar-hidden');
        }
        else {
            $('#navbar-summary .navbar').addClass('navbar-hidden');

        }
    });
})(jQuery);
