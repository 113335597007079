import {Tooltip} from 'bootstrap'
import Prism from 'prismjs';
import "prismjs/plugins/line-numbers/prism-line-numbers";

window.addEventListener("DOMContentLoaded", () => {

    // Enable Prism.js code syntaxt highlighting
    setTimeout(Prism.highlightAll);
    
    // Enable bootstrap tooltips on <abbr> elements
    const abbr = document.querySelectorAll('article abbr, .article-recommended abbr')
    abbr.forEach(el => {
        const tooltip = new Tooltip(el, {
            placement: 'top'
        })
    }) 

});
