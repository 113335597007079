// TODO: Remove jQuery dependency
(function($){
    //  Default values
    var cookieName = 'hasTrackingConsent';
    var cookieDateName = 'trackingConsentDate';
    var expirationDays = 7;

    // Get values from global vars (passed from PHP) if set
    if (typeof window !== 'undefined') {
        if (typeof window.trackingConsentCookieName !== 'undefined') {
            cookieName = window.trackingConsentCookieName;
        }
        if (typeof window.trackingConsentExpirationDays !== 'undefined') {
            expirationDays = window.trackingConsentExpirationDays;
        }
    }

    const trackingConsent = $('#tracking-consent');
    const trackingConsentAcceptBtn = $('#tracking-consent-btn-accept');
    const trackingConsentRejectBtn = $('#tracking-consent-btn-reject');

    const getHasConsent = () => {
        return window.localStorage.getItem(cookieName) === 'true'
    }
    const getHasDecided = () => {
        return window.localStorage.getItem(cookieName) !== null
    }
    const setHasConsent = (val = true) => {
        window.localStorage.setItem(cookieName, val ? 'true' : 'false');
    }

    const setDecisionDate = () => {
        var now = new Date().getTime();
        window.localStorage.setItem(cookieDateName, now);
      }
    
    const isExpired = () => {
        if (window.localStorage.getItem(cookieDateName) === null) return true;
        const cookieDate = window.localStorage.getItem(cookieDateName)
        const then = new Date(parseInt(cookieDate));
        const now = new Date();
        const msToDayFactor = (1000 * 3600 * 24);
        const daysBetween = Math.floor((now.getTime() - then.getTime()) / msToDayFactor)
        return daysBetween > expirationDays;
    }

    const removeCookie = () => {
        window.localStorage.removeItem(cookieName)
        window.localStorage.removeItem(cookieDateName)
    }

    const hideComponent = () => {
        trackingConsent.hide();
    }
    const showComponent = (callback) => {
        trackingConsent.show(0, callback);
    }
    const hideModal = () => {
        trackingConsent.find('.tracking-consent-modal').removeClass('show').addClass('hide');
        trackingConsent.find('.tracking-consent-modal-backdrop').removeClass('show').addClass('hide');
        setTimeout(hideComponent, 500)
    }
    const showModal = () => {
        trackingConsent.find('.tracking-consent-modal').removeClass('hide').addClass('show');
        trackingConsent.find('.tracking-consent-modal-backdrop').removeClass('hide').addClass('show');
    }

    const acceptTracking = function () {
        setDecisionDate();
        setHasConsent(true);
        hideModal();
    }
    const rejectTracking = function () {
        setDecisionDate();
        setHasConsent(false);
        hideModal();
    }

    const hasDecided = getHasDecided();
    const hasConsent = getHasConsent();

    const init = function() {
        if (isExpired()) {
            removeCookie()
            showComponent(showModal);
        } else if (hasDecided) {
            hideComponent();
        }
        else {
            showComponent(showModal);
        }

        trackingConsentAcceptBtn.on('click', acceptTracking);
        trackingConsentRejectBtn.on('click', rejectTracking);
    }

    init();
})(jQuery);