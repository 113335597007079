import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCheck,
  faExternalLink,
  faImageSlash,
  faTag,
  faTags,
  faTimes,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faLinkedin,
  faGithubSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

// Only icons on this list will be rendered
library.add(
  faExternalLink,
  faImageSlash,
  faAngleUp,
  faAngleRight,
  faAngleLeft,
  faAngleDown,
  faUser,
  faTags,
  faTag,
  faCheck,
  faTimes,
  faLinkedin,
  faGithubSquare,
  faFacebookSquare
);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
window.addEventListener("DOMContentLoaded", () => {
  dom.i2svg();
  dom.watch();
});
