import baguetteBox from 'baguettebox.js/dist/baguetteBox'
import LazyLoad from "vanilla-lazyload";

(function ($) {

    // Enable baguettebox.js, a lightbox-like plugin (zoom images when linked to larger ones)
    // TODO: Remove jQuery dependency

    if(typeof baguetteBox!=undefined) {
        var scrollInit = 0;
        var hideOnScroll = function() {
            var scrollCurrent = $(document).scrollTop();
            var scrollDelta = scrollCurrent - scrollInit;
            if (scrollDelta > 50) {
                $("#close-button").trigger("click");
            }
            scrollInit = scrollCurrent;
        }
        baguetteBox.run('.article-content',{
            buttons: false,
            titleTag: true,
            async: true,
            afterShow: function() {
                $(window).on('scroll', hideOnScroll);
            },
            afterHide: function() {
                $(window).off('scroll', hideOnScroll);
            }
        });
    }

    /**
     * Lazy loader [disabled]
     */

    // const lazyImg = document.querySelectorAll('.article-content img[src]')
    // const lazyLoadOptions = {
    //     elements_selector: "[loading=lazy]",
    //     to_webp: true,
    //     data_src: "src",
    //     data_srcset: "srcset",
    //     show_while_loading: true,
    //     callback_error: element => {
    //         // BUG: placeholder.com is unavailable in 2025
    //         element.src = "https://via.placeholder.com/256x256?text=Image";
    //     }
    // };

    // // BUG: Wordpress 5.5+ doesn't apply [loading=lazy] to images withour explicit width+height. This script assumes otherwise and replaces [src] attributes in all <img> tags
    // lazyImg.forEach(img => {
    //     img.setAttribute('data-src', img.getAttribute('src'))
    //     img.removeAttribute('src')
    // })

    // const pageLazyLoad = new LazyLoad(lazyLoadOptions);
})(jQuery);