window.addEventListener("DOMContentLoaded", () => {
    var reading_element = document.querySelector('#progress-reading');

    if (reading_element) {

        var cover = document.querySelector('#cover'),
            article = document.querySelector('#article');

        var scroll_start,
            percent,
            article_height,
            scroll = 0,
            from_top = 0,
            colors = [
                //"#3498db",
                "#00B2AD",
                "#2ecc71"
                //"#f1c40f",
                //"#e67e22",
                //"#c0392b",
                //"#e74c3c",
                //"#9b59b6"
            ];

        article_height = cover.clientHeight + article.clientHeight - window.innerHeight + 150;
        scroll_start = cover.offsetTop;
        percent = 0;

            var progress_reading_scroll = function () {
                scroll = document.querySelector('html').scrollTop || document.querySelector('body').scrollTop;

                from_top = scroll - scroll_start;
                percent = from_top / article_height * 100;
                percent = percent < 0 ? 0 : percent > 100 ? 100 : percent;

                reading_element.className = !percent ? 'hidden' : '';
                reading_element.style.width = percent + '%';

                /**/
                // Change color on progress
                reading_element.style.backgroundColor = colors[Math.floor(percent / (100 / colors.length) )];
                /**/
            };

            var progress_reading_resize = function () {

                article_height = cover.clientHeight + article.clientHeight - window.innerHeight + 150;
                scroll_start = cover.offsetTop;

                progress_reading_scroll();
            };


            window.addEventListener('scroll', progress_reading_scroll);
            window.addEventListener('resize', progress_reading_resize);
            window.addEventListener('load', progress_reading_resize);

    }
});
