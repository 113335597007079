// TODO: Remove jQuery dependency
(function ($) {
    var back_to_top = $('a.back-to-top');
    var is_mobile;
    var scroll_top;
    var scroll_top_old;

    var back_to_top_sizes = function () {
        is_mobile = $('.back-to-top-checker.d-inline.d-md-none').css('display') !== 'none';
        if(is_mobile){
            back_to_top.css('top', $('.navbar-fixed-top').first().outerHeight() + 15);
        }else{
            back_to_top.css('top', '');
        }
    };

    if (back_to_top.length) {
        back_to_top.click(function (e) {
            $('html,body').stop(true).animate({scrollTop: 0}, 1000, function(){
                document.location.hash = "";
            });

            e.stopPropagation();
            e.preventDefault();
        });
        // scroll event for desktop
        $(window).scroll(function () {
            scroll_top = $('html').scrollTop() || $('body').scrollTop();

            if (!is_mobile) {
                back_to_top.css('top', false);

                if (scroll_top > 0) {
                    back_to_top.addClass('active');
                } else {
                    back_to_top.removeClass('active');
                }
            } else {

                if (scroll_top_old > scroll_top && scroll_top > 0) {
                    back_to_top.addClass('active');
                } else {
                    back_to_top.removeClass('active');
                }
            }
            scroll_top_old = scroll_top;
        });

        // update on resize
        $(window).resize(back_to_top_sizes);
        back_to_top_sizes();
    }
})(jQuery);
